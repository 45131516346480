/**
 * Layout component that queries for data
 * with Gatsby's useStaticQuery component
 *
 * See: https://www.gatsbyjs.org/docs/use-static-query/
 */

import React from "react"
import PropTypes from "prop-types"
import styled from "styled-components"
import { Heading } from "../bruin"
import { Link } from "gatsby"
import { ThemeProvider } from "styled-components"
import { standard, GlobalStyle } from "../bruin/themes"

const PageContainer = styled.div`
	padding-top: 4.4rem;
	min-height: 100vh;
	margin: 0 auto;
	position: relative;
`

const StyledHeader = styled.header`
	position: fixed;
	width: 100%;
	background: none;
	z-index: 10000;
`
const StyledNav = styled.nav`
	display: flex;
	justify-content: space-between;
	align-items: center;
	width: 1260px;
	margin: 0 auto;
`

const StyledList = styled.ul`
	text-decoration: none;
	list-style: none;
	display: flex;
	justify-content: space-around;

	& li {
		text-transform: uppercase;
		font-size: 1.4rem;
		letter-spacing: 0.7px;
		margin-left: 5rem;
	}
`

const Layout = ({ children }) => {
	return (
		<ThemeProvider theme={standard}>
			<GlobalStyle />
			<StyledHeader>
				<StyledNav>
					<Link to="/">
						<Heading fontSize="sm" ml="lg">
							Grayhaber
						</Heading>
					</Link>
					<StyledList>
						<li>
							<Link to="/services">Services</Link>
						</li>
						<li>
							<Link to="/work">Work</Link>
						</li>
						<li>
							<Link to="/blog">Blog</Link>
						</li>
						<li>
							<Link to="/about">About</Link>
						</li>
					</StyledList>
				</StyledNav>
			</StyledHeader>
			<PageContainer>
				<main>{children}</main>
				<footer style={{ position: "absolute", bottom: "0", width: "100%", textAlign: "center" }}>
					© {new Date().getFullYear()} Grayhaber
				</footer>
			</PageContainer>
		</ThemeProvider>
	)
}

Layout.propTypes = {
	children: PropTypes.node.isRequired,
}

export default Layout
